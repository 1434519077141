import {disableBodyScroll, enableBodyScroll} from 'body-scroll-lock';

const targetElement = document.querySelector("body");
const lockButton = document.querySelectorAll('.lock-button');
const SCREEN_WIDTH = 768;
let options = {
    root: null,
    rootMargin: '0px',
    threshold: 1.0
};

let intersectionCounter = 0;

function initLock() {
    if (window.innerWidth < SCREEN_WIDTH) {

        let callback = (entries, observer) => {
            entries.forEach(entry => {

                if (entry.isIntersecting) {
                    let elem = entry.target;

                    if (entry.intersectionRatio >= 0.75) {
                        intersectionCounter++;
                        if (intersectionCounter > 0) {
                            disableBodyScroll(null);
                            lockButton.forEach(value => {
                                value.dataset.locked = "true"
                            });
                            observer.unobserve(entry.target);

                        }

                    }

                    console.log(`Counter: ${intersectionCounter}`)
                }
            });
        };

        let observer = new IntersectionObserver(callback, options);

        let target = document.querySelector('#curados-page');
        observer.observe(target);

        target = document.querySelector('#cocidos-page');
        observer.observe(target);

        lockButton.forEach(value => {
            value.addEventListener('click', function () {
                if (value.dataset.locked === "true") {
                    value.dataset.locked = "false";
                    value.classList.remove('fa-lock');
                    value.classList.add('fa-lock-open');
                    enableBodyScroll(null);
                }
            });
        });
    }
}

//window.onload = initLock;
