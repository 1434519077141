import Glide from '@glidejs/glide';
import breakpoints from "@glidejs/glide/src/components/breakpoints";

require('./locksection');
const SCREEN_WIDTH = 1024;
const BREAKPOINTS = {
    15000: {
        perView: 4,
        type: 'slider',
        startAt: 1,
        focusAt: 0,
    },
    1365: {
        perView: 4,
        type: 'slider',
        startAt: 1,
        focusAt: 0,
    },
    1023: {
        perView: 3
    },
    767: {
        perView: 1,
        gap: 20
    }
};

const BREAKPOINT_PRODUCT_LIST = {
  1023: {
    perView: 2,    
  },
  767: {
    perView: 1,
    gap: 20,
    autoplay: 5000,
    animationDuration: 3500,
  },
};
let glide;
let previousIndex = 0;

var VideoCarousel = function (Glide, Components, Events){
  return {
    modify(translate) {
      if (!glide) {
        return 0;
      }

      const i = glide.index;
      const prevI = previousIndex;

      previousIndex = i;

      if (i === prevI) {
        return 0;
      } else if (
        (Math.abs(i - prevI) === 1 && i > prevI) ||
        (i === 0 && prevI === glide._c.Html.slides.length - 1)
      ) {
        glide._c.Html.wrapper.classList.remove("glide__slides--reverse");
      } else {
        glide._c.Html.wrapper.classList.add("glide__slides--reverse");
      }

      return 0;
    },
  };
}

$(document).ready(async function() {              
    if($('#home-carousel').length){
        let homeCarousel = new Glide("#home-carousel", {
          startAt: 0,
          type: "carousel",
          focusAt: 0,
          perView: 1,
          gap: 0,
          autoplay: 7000,
          hoverpause: false
        });
        var company_info = $('.company_info')
        var banner_company_id = $('.slide_info');

        //esconde el elemento que esta visualizandose en el slider
        /*homeCarousel.on('run.after', function() {            
            var vars =  banner_company_id[homeCarousel.index+1].id.split("_");
            var id= vars[1];
            company_info.filter('.hidden_company').removeClass('hidden_company');
            company_info.filter('#'+id).addClass('hidden_company');
          });*/
        if(window.innerWidth > 768 && $('.home-banner').length > 1)
            homeCarousel.mount();
    }

    if($('#home-mobile-carousel').length){
        let homeCarousel = new Glide("#home-mobile-carousel", {
          startAt: 0,
          type: "carousel",
          focusAt: 0,
          perView: 1,
          gap: 0,
          autoplay: 7000,
          hoverpause: false,
        });        
        
        var company_info = $('.company_info')
        var banner_company_id = $('.slide_info');

        //esconde el elemento que esta visualizandose en el slider
        /*homeCarousel.on('run.after', function() {            
            var vars =  banner_company_id[homeCarousel.index+1].id.split("_");
            var id= vars[1];                        
            company_info.filter('.hidden_company').removeClass('hidden_company');            
            company_info.filter('#'+id).addClass('hidden_company');                        
          });*/

        if(window.innerWidth <= 768 && $('.home-banner').length > 1)
            homeCarousel.mount();
    }


    if($('#first-carousel').length){
        let firstCarousel = new Glide('#first-carousel', {
            startAt: 0,
            type: 'carousel',
            focusAt: 0,
            perView: 4,
            gap: 30,
            peek: {
                before: 60,
                after: 60
            },
            breakpoints: BREAKPOINTS
        });

        if (window.innerWidth >= SCREEN_WIDTH) {
            firstCarousel.disable();
        }

        firstCarousel.mount();
    }

    if($('#second-carousel').length){
        let secondCarousel = new Glide('#second-carousel', {
            startAt: 0,
            type: 'carousel',
            focusAt: 0,
            perView: 4,
            gap: 30,
            peek: {
                before: 60,
                after: 60
            },
            breakpoints: BREAKPOINTS
        });

        if (window.innerWidth >= SCREEN_WIDTH) {
            secondCarousel.disable();
        }

        secondCarousel.mount();
    }
    var query = window.location.search.substring(1);
    

    if($('#productCard-carousel').length){
        let productCardCarousel = new Glide('#productCard-carousel',{
            startAt: 0,
            type: 'slider',
            focusAt: 'center',
            perView: 1,
            gap: 0,
            animationTimingFunc:"ease",
            animationDuration: 1200,
            rewindDuration:1200,
            autoplay: 7000    
        });
        if(query !== ""){    
            var vars = query.split("=");
            var id= vars[1];
            var product;
            var cards = 0;
            var location = window.location.href;
            var dataInfo = "../javascript/products.json";
            
            if (location.includes('/fr'))
              dataInfo = "../javascript/products.json";
            if (location.includes("/en"))
                dataInfo = "../javascript/products.json";

            $.getJSON(dataInfo, function (data) {
              product = getObjects(data, "alt", id);

              product.forEach((element) => {
                $("#product-banner").append(
                  '<img class="w-full" src="https://oresdemo.bigbangfood.es/images/productos/banner/' +
                    element.banner_img +
                    '" alt="' +
                    element.alt +
                    '"><img class="w-full banner_md" src="https://oresdemo.bigbangfood.es/images/productos/banner/' +
                    element.banner_img_md +
                    '" alt="' +
                    element.alt +
                    '">'
                );
                $("#family-item").append(
                  '<h2 class="subtitle">' +
                    element.family[0].title +
                    "</h2><p>" +
                    element.family[0].text +
                    "</p>"
                );

                //logica de carousel familia
                if ($("#productFamily-carousel")) {
                  let familyCarousel = new Glide("#productFamily-carousel", {
                    startAt: 0,
                    type: "carousel",
                    focusAt: 0,
                    perView: 1,
                    gap: 0,
                    autoplay: 5000,
                    hoverpause: false,
                  });
                  element.family[0].images_lg.forEach((item) => {
                    $("#family-slides").append(
                      '<div class="glide__slide card-slide family-banner"><img class="slide_info w-full" src="https://oresdemo.bigbangfood.es/images/productos/family/' +
                        item.img +
                        '" alt="' +
                        item.alt +
                        '"></div>'
                    );
                  });
                  if (
                    window.innerWidth > 768 &&
                    $(".family-banner").length > 1
                  ) {
                    $("#family-arrows").append(
                      '<div class="glide__arrows" data-glide-el="controls"><button style="text-align:left" class="glide__arrow glide__arrow--left family-arrow" data-glide-dir="<"><img src="https://oresdemo.bigbangfood.es/images/productos/family/flecha-ores.png" alt="izq"/></button><button style="text-align: right;padding-right: 2%;" class="glide__arrow glide__arrow--right family-arrow" data-glide-dir=">"><img style="transform: rotate(180deg);" src="https://oresdemo.bigbangfood.es/images/productos/family/flecha-ores.png" alt="derecha"/></button></div>'
                    );
                    familyCarousel.mount();
                  }
                }

                if ($("#productFamily-mobile-carousel")) {
                  let familyMobileCarousel = new Glide(
                    "#productFamily-mobile-carousel",
                    {
                      startAt: 0,
                      type: "carousel",
                      focusAt: 0,
                      perView: 1,
                      gap: 0,
                      autoplay: 5000,
                      hoverpause: false,
                    }
                  );
                  element.family[0].images_md.forEach((item) => {
                    $("#family-mobile-slides").append(
                      '<div class="glide__slide card-slide family-mobile-banner"><img class="slide_info w-full" src="https://oresdemo.bigbangfood.es/images/productos/family/' +
                        item.img +
                        '" alt="' +
                        item.alt +
                        '"></div>'
                    );
                  });
                  if (
                    window.innerWidth <= 768 &&
                    $(".family-mobile-banner").length > 1
                  ) {
                    $("#family-arrows-mobile").append(
                      '<div class="glide__arrows" data-glide-el="controls"><button style="text-align: left;display: grid;vertical-align: top;left: 1em;" class="glide__arrow glide__arrow--left family-arrow" data-glide-dir="<"><img src="https://oresdemo.bigbangfood.es/images/productos/family/flecha-ores.png" alt="izq"/></button><button style="text-align: right;display: grid;vertical-align: top;right: 1em;" class="glide__arrow glide__arrow--right family-arrow" data-glide-dir=">"><img style="transform: rotate(180deg);" src="https://oresdemo.bigbangfood.es/images/productos/family/flecha-ores.png" alt="derecha"/></button></div>'
                    );
                    familyMobileCarousel.mount();
                  }
                }

                //logica de carousel video de familia
                if ($("#productFamily-video-carousel")) {
                  let familyVideoCarousel = new Glide(
                    "#productFamily-video-carousel",
                    {
                      startAt: 0,
                      type: "carousel",
                      focusAt: 0,
                      perView: 1,
                      gap: 0,
                      autoplay: 13000,
                      hoverpause: true,
                      animationTimingFunc: "ease",
                      animationDuration: 1200,
                      rewindDuration: 1200,
                    }
                  );
                  let id_video= 0;
                  element.family[0].family_videos.forEach((item) => {
                    $("#family-video-slides").append(
                      '<div class="family-video-banner glide__slide " style="height:600px"> '+
                      '<div id="video_family'+id_video+'" class="video_family" style="background-image:url(https://oresdemo.bigbangfood.es/images/productos/family/videos/Easybox/'+item.cover+');"></div> '+
                      '<div class="image_opacity"><div class="txt-video-family"> '+                      
                      '<h2 class="video-tittle">'+item.tittle+'</h2>'+
                      '<p class="video-desc">'+item.description+'</p>'+
                      '<video controls id="promo-video'+id_video+'" style="display:none;"> '+
                      '<source src="https://oresdemo.bigbangfood.es/images/productos/family/videos/Easybox/'+item.url+'" type="video/mp4">'+
                      '"Tu navegador no soporta este tipo de contenido." '+
                      '</video></div></div>'+
                      '<div class="video-play-container"><img class="video-play" data-fancybox href="#promo-video'+id_video+'" src="https://oresdemo.bigbangfood.es/images/acerca-sources/play.png" data-animation-effect="false" alt="Reproducir Video"></div></div>'
                    );
                    id_video = id_video+1;
                  });
                  if (window.innerWidth > 768 && $(".family-video-banner").length > 1) {
                    $("#family-video-arrows").append(
                      '<div class="glide__arrows" data-glide-el="controls"><button style="text-align:left" class="glide__arrow glide__arrow--left family-arrow family-video-arrow" data-glide-dir="<"><img src="https://oresdemo.bigbangfood.es/images/productos/family/flecha-ores.png" alt="izq"/></button><button style="text-align: right;padding-right: 2%;" class="glide__arrow glide__arrow--right family-arrow family-video-arrow" data-glide-dir=">"><img style="transform: rotate(180deg);" src="https://oresdemo.bigbangfood.es/images/productos/family/flecha-ores.png" alt="derecha"/></button></div>'
                    );
                    familyVideoCarousel.mount();
                  }
                }
                if ($("#productFamily-video-mobile-carousel")) {
                  let familyVideoMobileCarousel = new Glide(
                    "#productFamily-video-mobile-carousel",
                    {
                      startAt: 0,
                      type: "carousel",
                      focusAt: 0,
                      perView: 1,
                      gap: 0,
                      autoplay: 13000,
                      hoverpause: true,
                      animationTimingFunc: "ease",
                      animationDuration: 1200,
                      rewindDuration: 1200,
                    }
                  );
                  let id_video= 0;
                  element.family[0].family_videos.forEach((item) => {
                    $("#family-video-mobile-slides").append(
                      '<div class="glide__slide family-video-mobile-banner"> '+
                      '<div class="video_family" style="background-image:url(../images/productos/family/videos/Easybox/'+item.cover_mobile+');"></div> '+                      
                      '<div class="image_opacity"><div class="txt-video-family"> '+                                            
                      '<h2 class="video-tittle">'+item.tittle+'</h2>'+
                      '<p class="video-desc">'+item.description+'</p>'+
                      '<video controls id="promo-video'+id_video+'" style="display:none;"> '+
                      '<source src="../images/productos/family/videos/Easybox/'+item.url+'" type="video/mp4">'+
                      '"Tu navegador no soporta este tipo de contenido." '+
                      '</video></div></div>'+
                      '<div class="video-play-container"><img class="video-play" data-fancybox href="#promo-video'+id_video+'" src="../images/acerca-sources/play.png" data-animation-effect="false" alt="Reproducir Video"></div></div>'
                    );
                    id_video = id_video+1;
                  });

                  if (window.innerWidth <= 768 && element.family[0].family_videos.length > 1) {
                    $("#family-video-mobile-arrows").append(
                      '<div class="glide__arrows" data-glide-el="controls"><button style="text-align:left" class="glide__arrow glide__arrow--left mobile-arrow--left  family-arrow family-video-arrow" data-glide-dir="<"><img src="https://oresdemo.bigbangfood.es/images/productos/family/flecha-ores-blanco.png" alt="izq"/></button><button style="text-align: right;padding-right: 2%;" class="glide__arrow glide__arrow--right mobile-arrow--right family-arrow family-video-arrow" data-glide-dir=">"><img style="transform: rotate(180deg);" src="https://oresdemo.bigbangfood.es/images/productos/family/flecha-ores-blanco.png" alt="derecha"/></button></div>'
                    );
                    familyVideoMobileCarousel.mount();
                  }
                }



                if ($("#productList-carousel")) {
                  let productListCarousel;
                  if (element.family[0].product_list.length > 6) {
                    productListCarousel = new Glide("#productList-carousel", {
                      startAt: 0,
                      type: "carousel",
                      focusAt: 0,
                      perView: 4,
                      gap: 0,
                      autoplay: 10000,
                      animationDuration: 8000,
                      animationTimingFunc: "cubic-bezier(.32,.18,.24,1.01)",
                      hoverpause: false,
                      breakpoints: BREAKPOINT_PRODUCT_LIST,
                    });
                  } else {
                    productListCarousel = new Glide("#productList-carousel", {
                      startAt: 0,
                      type: "carousel",
                      focusAt: 0,
                      perView: 3,
                      gap: 0,
                      autoplay: 10000,
                      animationDuration: 8000,
                      animationTimingFunc: "cubic-bezier(.32,.18,.24,1.01)",
                      hoverpause: false,
                      breakpoints: BREAKPOINT_PRODUCT_LIST,
                    });
                  }

                  $("#product-list").css(
                    "background-color",
                    element.family[0].product_list_bg
                  );

                  element.family[0].product_list.forEach((item) => {
                    $("#product-slides").append(
                      '<div class="glide__slide"><div style="display:flex;align-items:center;justify-content:center;"><img class="ores-pointer" src="https://oresdemo.bigbangfood.es/images/Ores-blanco.png" alt="ores-poiner"><span style="padding-left:.5em">' +
                        item.name +
                        "</span></div></div>"
                    );
                    $("#full-list").append(
                      '<li><img class="ores-pointer" src="https://oresdemo.bigbangfood.es/images/Ores-blanco.png" alt="ores-pointer"><span>' +
                        item.name +
                        "</span></li>"
                    );
                  });

                  /*if (element.family[0].product_list.length > 6){
                          productListCarousel.on("run.before", (evt) => {
                          const scrollSteps = productListCarousel.settings.perView;
                          evt.steps =
                              evt.direction === ">" ? -scrollSteps : scrollSteps;
                          });    
                      }*/
                  if (element.family[0].product_list.length > 3) {
                    $(".show_full_list").css("display", "block");
                    productListCarousel.mount();
                    $("#full-list").hide();
                  } else if (
                    element.family[0].product_list.length > 1 &&
                    window.innerWidth <= 768
                  ) {
                    $(".show_full_list").css("display", "block");
                    productListCarousel.mount();
                    $("#full-list").hide();
                  } else {
                    $("#productList-carousel").hide();
                    $("#details_button").hide();
                    $(".show_product_carousel").hide();
                  }
                  if (
                    (element.family[0].product_list.length == 2 ||
                      element.family[0].product_list.length == 4) &&
                    window.innerWidth > 768
                  ) {
                    $("#full-list>li").css("flex", "1 0 50%");
                    $("#full-list>li").css("-ms-flex", "1 0 50%");
                    $("#full-list>li").css("max-width", "50%");
                    $("#full-list").addClass("full_list_pair");
                  }
                  if (element.family[0].product_list.length == 1) {
                    $("#full-list>li").css("flex", "1 0 100%");
                    $("#full-list>li").css("-ms-flex", "1 0 100%");
                    $("#full-list>li").css("max-width", "100%");
                    $("#full-list>li").css("justify-content", "center");
                    $("#full-list>li").css("margin", "0");
                  }
                }

                element.cards.forEach((card) => {
                  $("#card-slides").append(
                    '<div class="glide__slide"><div class="product-card" draggable="false" contenteditable="false"><div class="card-image"><img src="https://oresdemo.bigbangfood.es/images/productos/cards/' +
                      card.image_lg +
                      '" alt="' +
                      element.alt +
                      '"><img class="ores-logo" src="https://oresdemo.bigbangfood.es/images/productos/cards/' +
                      card.image_md +
                      '" alt="' +
                      element.alt +
                      '"></div><div class="product-card-content"><p class="card-title">' +
                      card.title +
                      '</p><h3 class="subtitle">' +
                      card.subtitle +
                      '</h3><div class="content-line"></div><p class="card-body">' +
                      card.text +
                      "</p></div></div></div>"
                  );
                });

                $(".product-page").css("background-color", element.card_bg);
                cards = element.cards.length;
                if (element.cards.length > 1) {
                  for (let index = 0; index < element.cards.length; index++) {
                    $("#card-bullets").append(
                      '<button class="glide__bullet" data-glide-dir="=' +
                        index +
                        '"></button>'
                    );
                  }
                  $("#card-arrows").append(
                    '<div class="glide__arrows" data-glide-el="controls"><button class="glide__arrow glide__arrow--left" data-glide-dir="<"><i class="fas fa-caret-left"></i></button><button class="glide__arrow glide__arrow--right" data-glide-dir=">"><i class="fas fa-caret-right"></i></button></div>'
                  );
                }
              });
              if (cards > 1) {
                productCardCarousel.mount();
              } else {
                $("#card-slides>.glide__slide").css("opacity", "1");
                $("#card-slides>.glide__slide").css("position", "initial");
              }
            });
        }                          
    }

    if($('#brands-carousel').length){
        let brands = new Glide('#brands-carousel', {
            startAt: 0,
            type: 'carousel',
            focusAt: 0,
            perView: 5,
            gap: 0,
            autoplay: 3000,
            breakpoints: {
                1680: {
                    perView: 4
                },
                1260: {
                    perView: 3
                },
                840: {
                    perView: 2
                },
                420: {
                    perView: 1
                }
            }
        });

        brands.mount();
    }        

    if($('#submenu-slider-curado').length){
        let submenu = new Glide('#submenu-slider-curado', {
            startAt: 0,
            type: 'slider',
            focusAt: 0,
            perView: 1,
            gap: 0,
            autoplay: false
        });
          
        submenu.mount();

        let submenu1 = new Glide("#submenu-slider-cocido", {
          startAt: 0,
          type: "slider",
          focusAt: 0,
          perView: 1,
          gap: 0,
          autoplay: false,
        });
        if(window.innerWidth <= 768 && window.innerHeight > 650)
            submenu1.mount();
    }
    
    await sleep(1000);
    $(".nuestras_marcas").removeClass("nuestras_marcas_hide");
    $(".nuestras_marcas").addClass("nuestras_marcas_show");
    await sleep(500);
    $("#loading").addClass("hide-loader");
});



function getObjects(obj, key, val) {
    var objects = [];
    for (var i in obj) {
        if (!obj.hasOwnProperty(i)) continue;
        if (typeof obj[i] == 'object') {
            objects = objects.concat(getObjects(obj[i], key, val));
        } else if (i == key && obj[key] == val) {
            objects.push(obj);
        }
    }
    return objects;
}